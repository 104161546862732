import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
  const services = [
    {
      heading: "Hotel Disinfecting",
      text: "We provide professional COVID-19 cleaning and disinfecting services. Ask about our Wellness Butler program.",
      image: data.disinfecting.childImageSharp.gatsbyImageData,
      link: "/hotel-disinfecting/",
    },
    {
      heading: "Hotel Housekeeping",
      text: "We're committed to five-star cleanliness and deliver the full scope of hotel housekeeping services.",
      image: data.housekeeping.childImageSharp.gatsbyImageData,
      link: "/hotel-housekeeping/",
    },
    {
      heading: "Hotel & Hospitality Staffing",
      text: "We offer a full scope of hospitality staffing to ensure you have a quality workforce.",
      image: data.staffing.childImageSharp.gatsbyImageData,
      link: "/hospitality-staffing/",
    },
    {
      heading: "Commercial Cleaning",
      text: "From offices to common areas and restrooms, we'll take care of your commercial space.",
      image: data.commercial.childImageSharp.gatsbyImageData,
      link: "/commercial-cleaning/",
    },
    {
      heading: "Restoration Services",
      text: "We specialize in restoring stone and marble flooring - everything from travertine to Italian marble.",
      image: data.restoration.childImageSharp.gatsbyImageData,
      link: "/hotel-restoration/",
    },
    {
      heading: "Resort Cleaning",
      text: "Our highly trained staff can handle all your resort cleaning needs from top to bottom.",
      image: data.resort.childImageSharp.gatsbyImageData,
      link: "/resort-cleaning/",
    },
    {
      heading: "Spa Cleaning",
      text: "Our exceptional cleaning ensures your customer's spa experience is the best it can be.",
      image: data.spa.childImageSharp.gatsbyImageData,
      link: "/spa-cleaning/",
    },
    {
      heading: "Restaurant Cleaning",
      text: "We take the hassle out of cleaning so you can focus on food and service. ",
      image: data.restaurant.childImageSharp.gatsbyImageData,
      link: "/restaurant-cleaning/",
    },
    {
      heading: "Commercial Kitchen Cleaning",
      text: "We set your culinary team up for success by providing a clean and sanitary kitchen.",
      image: data.kitchen.childImageSharp.gatsbyImageData,
      link: "/commercial-kitchen-cleaning/",
    },
    {
      heading: "Hard Surface Floor Care",
      text: "We take special care of your hard surface flooring to ensure an inviting atmosphere.",
      image: data.floor.childImageSharp.gatsbyImageData,
      link: "/hard-surface-floor-care/",
    },
  ];

  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Our Services | Hotel Cleaning & Hospitality Staffing"
        description="When you partner with Hotel Cleaning Services, you get a skilled, experienced team of professionals committed to delivering five-star experiences."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-20 lg:pt-40 mb-16 md:mb-18">
        <div className="container">
          <header className="max-w-3xl mb-12 md:mb-16">
            <h1>Services</h1>
            <p>
              When you partner with Hotel Cleaning Services, you get a skilled,
              experienced team of professionals committed to delivering
              five-star experiences.
            </p>
          </header>

          <div className="grid md:grid-cols-2 md:gap-x-7 gap-y-6 md:gap-y-10 items-center">
            {services.map((service, i) => (
              <AniLink
                href={service.link}
                className="group relative no-underline py-8 px-6 md:px-8 h-full"
                key={i}
              >
                <div className="absolute left-0 top-0 w-full h-full overflow-hidden">
                  <GatsbyImage
                    image={service.image}
                    className="w-full h-full transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                  />
                </div>
                <div className="relative max-w-xs">
                  <h3 className="text-gray-50 text-xl mb-2.5">
                    {service.heading}
                  </h3>
                  <p className="mb-0">{service.text}</p>
                </div>
              </AniLink>
            ))}
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    disinfecting: file(
      relativePath: { eq: "services/Hub/1.0 hub - Disifecting.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 580)
      }
    }
    housekeeping: file(
      relativePath: { eq: "services/Hub/1.1 Hub  - housekeeping.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 580)
      }
    }
    staffing: file(
      relativePath: { eq: "services/Hub/2.0 Hub - staffing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 580)
      }
    }
    commercial: file(
      relativePath: { eq: "services/Hub/2.1 hub - commercial.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 580)
      }
    }
    restoration: file(
      relativePath: { eq: "services/Hub/3.0 hub - restoration.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 580)
      }
    }
    resort: file(relativePath: { eq: "services/Hub/3.1 Hub - resort.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 580)
      }
    }
    spa: file(relativePath: { eq: "services/Hub/4.0 hub - Spa.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 580)
      }
    }
    restaurant: file(
      relativePath: { eq: "services/Hub/4.1 Hub - restaurant.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 580)
      }
    }
    kitchen: file(relativePath: { eq: "services/Hub/5.0 Hub - kitchen.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 580)
      }
    }
    floor: file(relativePath: { eq: "services/Hub/5.1 Hub - Floor.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 580)
      }
    }
  }
`;
export default Page;
